import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from "../components/layout"

import { kebabCase } from 'lodash';
import {MainList,CatList} from '../components/utils.js'

// should be exported to both tags.js and projects.js
const CategorySet = (props) => {
	return (
		<ul className={"tagList"}>
			{props.allCategories.map(category => (
			<Link key={category.fieldValue} to={`/categories/${kebabCase(category.fieldValue)}/`}>
				<li>
					{category.fieldValue} ({category.totalCount})
				</li>
			</Link>
			))}
		</ul>
	)
}

const CategoryList = ({ data }) => {
	const allCategories = data.projects.group;
	const totalCount = data.projects.totalCount;
	const totalTalk = data.talks.totalCount;
	return (
		<Layout>
			<div className={"container"}>
				<MainList project={true} total={totalCount} totalTalk={totalTalk}/>
				<CatList category={true}/>
				<CategorySet allCategories={allCategories} totalCount={totalCount}/>
			</div>
		</Layout>
	);
}

export default CategoryList;

export const pageQuery = graphql`
	query {
		projects: allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: {frontmatter: {template: {eq: "project"}}}
		) {
			totalCount
			group(field: frontmatter___category) {
				fieldValue
				totalCount
			}
		}
		talks:allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: {frontmatter: {template: {eq: "talk"}}}
		) {
			totalCount
		}
	}
`;